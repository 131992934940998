import { StudentExamStatus } from '@gmm/sdk/studentExams';

export const classes = {
  archiveDialog: {
    cancel: 'archive-class-dialog-cancel',
    confirm: 'archive-class-dialog-yes',
    root: 'archive-class-dialog-root',
  },
  assignAssignment: 'assign-assignment',
  availableAssignments: 'available-assignments',
  assignments: {
    assignmentDetailLink: (name: string) => `assignment-detail-link-${name}`,
    available: (name: string) => `classes-assignment-available-${name}`,
    confirmDeleteModal: {
      cancel: 'confirm-delete-assignment-cancel',
      confirm: 'confirm-delete-assignment-confirm',
      removeSkills: (isOn: boolean) => `remove-skills-checkbox-${isOn}`,
      root: 'confirm-delete-assignment-root',
      title: 'confirm-delete-assignment-title',
    },
    counter: 'classes-assignments-counter',
    defaultCheckbox: (name: string) => `classes-assignment-default-${name}`,
    delete: (name: string) => `classes-assignment-delete-${name}`,
    tab: 'classes-assignments-tab',
    tableId: 'assignments',
  },
  assignmentDetails: {
    assignedAt: 'assignment-details-assigned-at',
    pointsRequired: 'assignment-details-points-required',
    studentsCompleted: 'assignment-details-students-completed',
    tableId: 'assignment-details',
  },
  breadcrumbClassName: 'classes-breadcrumb-class-name',
  breadcrumbs: 'classes-breadcrumbs',
  breadcrumbBackArrow: 'classes-breadcrumb-back-arrow',
  breadcrumbWrapper: (classId: string) => `class-${classId}`,
  breadcrumbFinalCrumb: 'classes-breadcrumb-final-crumb',
  closeStudentReport: 'classes-close-student-report',
  createClass: {
    button: 'classes-my-classes-drawer-create-class-button',
    cancel: 'create-class-cancel-button',
    className: 'create-class-classname',
    errorMessage: 'create-class-classname-helper-text',
    form: 'create-class-form',
    save: 'create-class-save-button',
  },
  currentStatus: {
    main: 'current-status',
    student: {
      help: 'student-help',
      lastCorrect: 'student-last-correct',
      lastCorrectWithWarning: 'student-last-correct-warning',
      link: 'student-link',
      name: 'student-name',
      online: 'student-online',
      workingOn: 'student-working-on',
    },
    tab: 'classes-current-status-tab',
    tableId: 'currentStatus',
    tablePaper: 'current-status-paper',
  },
  examAnalysis: {
    condensedIcon: 'exam-analysis-condensed-icon',
    createAssignment: 'classes-exam-analysis-create-assignment',
    problemPreview: {
      tableId: 'problemPreviewStudents',
    },
    root: 'classes-exam-analysis-root',
    tableId: 'examAnalysis',
  },
  exams: {
    autoUnlockCheckbox: (isOn: boolean) => `exam-auto-unlock-checkbox-${isOn}`,
    confirmDeleteModal: {
      cancel: 'confirm-delete-exam-cancel',
      confirm: 'confirm-delete-exam-confirm',
      title: 'confirm-delete-exam-title',
      root: 'confirm-delete-exam-root',
    },
    printModal: {
      cancel: 'print-exam-cancel',
      confirm: 'print-exam-confirm',
      form: {
        includeAnswerKey: 'print-exam-form-includeAnswerKey',
        root: 'print-exam-form-root',
        type: (type: string) => `print-exam-form-type-${type}`,
        typeRoot: 'print-exam-form-type-root',
      },
      title: 'print-exam-title',
      root: 'print-exam-root',
    },
    tab: 'classes-exams-tab',
  },
  messageClass: {
    field: 'broadcast-message-class-input',
    cancel: 'broadcast-message-class-cancel',
    save: 'broadcast-message-class-save',
    form: 'broadcast-message-class-form',
  },
  mixedReview: {
    actions: {
      activate: {
        button: 'mixed-review-actions-activate-button',
        disabledTooltip: 'mixed-review-actions-activate-disabled-tooltip',
      },
      createAssignment: {
        button: 'mixed-review-actions-create-assignment-button',
        disabledTooltip:
          'mixed-review-actions-create-assignment-disabled-tooltip',
      },
      createExam: {
        button: 'mixed-review-actions-create-exam-button',
        disabledTooltip: 'mixed-review-actions-create-exam-disabled-tooltip',
      },
      deactivate: {
        button: 'mixed-review-actions-deactivate-button',
        disabledTooltip: 'mixed-review-actions-deactivate-disabled-tooltip',
      },
      delete: 'mixed-review-actions-delete-skill',
      menu: 'mixed-review-batch-actions-menu',
      title: 'mixed-review-batch-actions',
    },
    insights: {
      proficiency: (color: string) => `${color}-proficiency-statistics`,
      accuracy: (range: string) => `${range}-accuracy-statistics`,
    },
    selectCheckBox: {
      all: 'select-all-checkbox',
    },
    classLevelStatistics: {
      accuracy: 'class-level-statistics-accuracy',
      activeSkills: 'class-level-statistics-active-skills',
      main: 'class-level-statistics-main',
      pointsDistribution: 'class-level-statistics-points-distribution',
      rotationDays: 'class-level-statistics-rotation-days',
      tooltip: (name: string) => `class-level-statistics-${name}-tooltip`,
    },
    deleteDialog: {
      cancel: 'mixed-review-delete-dialog-cancel',
      confirm: 'mixed-review-delete-dialog-confirm',
      root: 'mixed-review-delete-dialog-root',
    },
    root: 'mixed-review-root',
    tab: 'mixed-review-tab',
    tableId: 'mixedReview',
    toggleActivate: 'toggle-activate',
    toolBar: 'detail-panel-toolbar',
  },
  pointsHistory: {
    dataTypeDropdown: 'points-history-data-type-dropdown',
    dateRangeEnd: 'date-range-end',
    dateRangeStart: 'date-range-start',
    root: 'points-history',
    tab: 'points-history-tab',
    tableId: 'pointsHistory',
  },
  renameDialog: {
    cancel: 'rename-class-dialog-cancel',
    confirm: 'rename-class-dialog-yes',
    nameField: 'rename-class-dialog-name-field',
    root: 'rename-class-dialog-root',
  },
  roster: {
    backToList: 'class-roster-back-to-list',
    choices: {
      create: 'class-roster-create-button',
      learnHow: 'class-roster-learn-how-button',
      search: 'class-roster-search-button',
    },
    create: {
      addAnother: 'create-students-add-another',
      cancel: 'create-students-cancel',
      conflicts: {
        back: 'create-students-dups-back-button',
        continue: 'create-students-dups-continue-button',
        existingStudents: {
          tableId: 'create-students-existing',
        },
        studentsToAdd: {
          tableId: 'create-students-to-add',
        },
      },
      confirm: {
        back: 'add-students-back',
        button: 'add-students-confirm',
        tableId: 'add-students-confirm',
      },

      newStudent: {
        firstName: (index: number) => `create-student-${index}:first-name`,
        lastName: (index: number) => `create-student-${index}:last-name`,
        schoolAssignedId: (index: number) =>
          `create-student-${index}:school-assigned-id`,
        remove: (index: number) => `create-student-${index}:remove`,
        row: (index: number) => `create-students-student-row=${index}`,
      },

      review: {
        done: 'create-students-summary-done',
        print: 'create-students-summary-print',
        tableId: 'create-students-summary',
      },
      submit: 'create-students-submit-button',
    },
    tab: 'class-roster-tab',
    search: {
      input: 'class-roster-search-input',
      name: 'student-search',
      tableId: 'studentSearchResults',
    },
    studentInClasses: {
      actions: {
        addStudents: 'class-roster-student-in-classes-add-students',
        addStudentsManually:
          'class-roster-student-in-classes-add-students-manually',
        classCode: 'class-roster-student-in-classes-class-code',
        classLink: 'class-roster-student-in-classes-class-link',
        print: 'class-roster-student-in-classes-print',
        printIcon: 'printIcon',
      },
      settings: {
        form: {
          cancel: 'edit-student-cancel',
          fields: {
            blockGames: 'student-settings-block-games-field',
            blockPenalty: 'student-settings-disable-penalties',
            readAloudEnabled: 'student-settings-read-aloud-enabled',
            firstName: 'edit-student-first-name-field',
            lastName: 'edit-student-last-name-field',
            limitMultipleChoiceToThree:
              'student-settings-limit-multiple-choice',
            schoolAssignedId: 'edit-student-school-assigned-id-field',
            username: 'edit-student-username-field',
          },
          remove: `student-info-delete-class`,
          root: 'edit-student-form-root',
          seePassword: 'see-password',
          submit: 'student-settings-form-submit',
        },
      },
      tableId: 'roster',
    },
  },
  selectedExam: {
    gradeTurnedIn: 'selected-exam-grade-turned-in',
    stop: 'stop-exam',
    stopModal: {
      cancel: 'stop-cancel',
      continue: 'stop-continue',
      title: 'stop-title',
      body: 'stop-body',
    },
    tableId: 'selected-exam-results',
    viewAnalysis: 'selected-exam-view-analysis',
  },
  settings: {
    allowRaisedHands: 'class-settings-allow-raised-hands',
    allowReplacements: 'class-settings-allow-replacements',
    allowReadAloud: 'class-settings-allow-read-aloud',
    allowWorkOnAnyProblems: 'class-settings-allow-any-problems',
    allowExamsOnMobile: 'class-settings-allow-exams-on-mobile',
    archiveClass: 'archive-class-button',
    requireStudentsToFinishDefaultAssignment:
      'class-settings-require-students-to-finish-default-assignment',
    tab: 'class-settings-tab',
    enableGame: (game: string) => `class-settings-enable-game-${game}`,
    enableGames: 'class-settings-enable-games',
    form: 'class-settings-form',
    metaData: 'class-settings-metadata',
    pointsPerGame: 'class-settings-point-per-game',
    printClass: {
      button: 'print-class',
      close: 'print-class-close',
      options: 'print-class-options',
      print: 'print-class-print',
    },
    renameClass: 'rename-class-button',
    replacementsPerDay: 'class-settings-replacements-per-day',
    subjectButton: 'class-settings-change-subject',
    subjectDisplay: 'class-subject-display',
    submit: 'class-settings-submit',
  },
  status: {
    chip: 'class-status-chip',
    icons: {
      mixedReview: 'icon-mixed-review',
      assignment: 'icon-assignment',
    },
  },
  studentData: {
    tab: 'student-data-tab',
    toolbar: 'detail-panel-toolbar',
    tableId: 'student-data-mixed-review-grid',
    studentReport: {
      activeSkills: {
        colors: 'active-skills-colors',
        expansionPanel: 'active-skills-expansion-panel',
        expansionPanelButton: 'active-skills-expansion-panel-button',
      },
      allSkills: {
        tableId: 'studentDataAllSkills',
      },
      metricHeader: (name: string) => `student-report-${name}`,
      studentCalendar: {
        hyperlink: 'student-calendar-hyperlink',
        button: 'student-calendar-button',
      },
      summary: {
        expansionPanel: 'summary-expansion-panel',
        expansionPanelButton: 'summary-expansion-panel-button',
      },
      tooltip: (name: string) => `student-report-${name}-tooltip`,
    },
  },
  studentDetail: {
    accordion: 'student-detail-accordion',
    close: 'student-detail-close',
    giveGameCredit: 'student-detail-give-game-credit',
    overrideSkill: 'override-skill',
    root: 'student-detail-root',
    sendMessage: {
      form: 'student-detail-send-message-form',
      input: 'student-detail-send-message-field',
      submit: 'student-detail-send-message-submit',
    },
  },
  studentExam: {
    confirm: `student-exam-confirm`,
    closeStudentExam: 'student-exam-close',
    grading: (type: string) => `student-exam-grading-${type}`,
    turnIn: 'student-exam-turnin',
    turnInModal: {
      cancel: 'student-exam-turnin-modal-cancel',
      confirm: 'student-exam-turnin-modal-cancel',
    },
    print: `student-exam-print`,
    printModal: {
      cancel: 'print-student-exam-cancel',
      confirm: 'print-student-exam-confirm',
      form: {
        includeAnswerKey: 'print-student-exam-form-includeAnswerKey',
        root: 'print-student-exam-form-root',
        type: (type: string) => `print-student-exam-form-type-${type}`,
        typeRoot: 'print-student-exam-form-type-root',
      },
      title: 'print-student-exam-title',
      root: 'print-student-exam-root',
    },
    problemChip: (problemPos: number) =>
      `student-exam-problem:problem-${problemPos}`,
    problemChipIcon: (problemPos: number, status: string) =>
      `student-exam-problem:problem-${problemPos}:${status}`,
    resultsDetails: `student-exam-details`,
    resultsHeader: `student-exam-header`,
    resultsPanel: `student-exam-panel`,
    saveGradeModal: {
      cancel: 'save-grade-exam-cancel',
      confirm: 'save-grade-exam-confirm',
      content: 'save-grade-exam-content',
      title: 'save-grade-exam-title',
      root: 'save-grade-exam-root',
    },
    score: `student-exam-score`,
    status: (status: StudentExamStatus) => `student-exam-status:${status}`,
    viewNextStudent: 'view-next-student',
    viewPreviousStudent: 'view-previous-student',
  },
  subjectFields: {
    subjectDropdown: 'class-subject-drop-down',
    subjectTextField: 'class-subject-text-field',
  },
  switchToMixedReview: 'switch-to-mixed-review',
  todaysGoal: {
    cancel: 'todays-goal-cancel-button',
    field: 'todays-goal',
    fieldInput: 'todays-goal-input',
    form: 'todays-goal-form',
    remove: 'todays-goal-remove-button',
    save: 'todays-goal-save-button',
  },
};
