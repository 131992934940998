import { AxiosRequestConfig } from 'axios';

interface Update {
  name?: string;
  klass?: {
    clearDefaultAssignment?: boolean;
    setToMixedReview?: boolean;
    setting?: {
      allowRaisedHands?: boolean;
      allowedWebGames?: string[];
      autoUnlockExamsProblems?: boolean;
      finishDefaultAssignment?: boolean;
      finishTargetedSkills?: boolean;
      gamesEnabled?: boolean;
      pointsPerGameCredit?: number;
      readAloudEnabled?: boolean;
      enrollmentOpen?: boolean;
    };
    subjects?: string[] | null;
  };
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/classes/${string}`;
}

/**
 * PATCH /api/v2/classes/{id}
 *
 * { name?: string, klass?: { setToMixedReview: boolean } }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/classes/${id}`,
    body: data,
    data,
  } as const;
};
