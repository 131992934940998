import { noop } from 'lodash';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Updater, useImmer } from 'use-immer';

import { ExerciseSkill } from '@gmm/sdk/exercises';
import { isTypedItem, useLatestRef } from '@gmm/ui';
import { createNamedContext } from '~/lib/createNamedContext';
import { useExercise } from '~/lib/hooks/useExercise';
import { getEmptyArray } from '~/lib/type-utils';
import { EXAM_PATH } from '~/work/skillDiscovery/utils';

const AddedSkillsContext = createNamedContext('AddSkillsContext', {
  skills: getEmptyArray<ExerciseSkill[]>(),
});
const SetSkillsContext = createNamedContext<
  Updater<{ skills: ExerciseSkill[] }>
>('SetSkillsContext', noop);

export const AddSkillsProvider: FC = ({ children }) => {
  const [state, setState] = useImmer<{ skills: ExerciseSkill[] }>(() => ({
    skills: [],
  }));

  return (
    <SetSkillsContext.Provider value={setState}>
      <AddedSkillsContext.Provider value={state}>
        {children}
      </AddedSkillsContext.Provider>
    </SetSkillsContext.Provider>
  );
};

interface UseAddSkills {
  addSkills: (skills: ExerciseSkill[], to?: string) => void;
  createAssignmentWithSkills: (skills: ExerciseSkill[]) => void;
  createExamTemplateWithSkills: (skills: ExerciseSkill[]) => void;
  isSkillAdded: (skillType: string) => boolean;
  resetSkills: () => void;
}

export const useAddSkills = (): UseAddSkills => {
  const history = useHistory();
  const setState = useContext(SetSkillsContext);
  const { skills } = useContext(AddedSkillsContext);
  const { data: exercise } = useExercise({ readOnly: true });

  const isSkillAdded = (skillType: string): boolean =>
    [...(exercise?.skills ?? []), ...skills].some(
      ({ type }) => type === skillType,
    );

  const addSkills = (
    skills: ExerciseSkill[],
    to = '/create/assignment',
  ): void => {
    if (!skills.length) return;

    const { pathname } = history.location;

    if (!exercise && !pathname.includes(to)) {
      const basePath = pathname.startsWith('/work') ? pathname : '/work';

      history.push(`${basePath}${to}`);
    }

    setState(draft => {
      skills
        // This was required for an e2e, but I couldn't trace the source
        .forEach(skill => {
          if (!isTypedItem(skill) || isSkillAdded(skill.type)) return;

          draft.skills.push(skill);
        });
    });
  };

  const resetSkills = useCallback(
    (): void =>
      setState(draft => {
        draft.skills = getEmptyArray<ExerciseSkill[]>();
      }),
    [setState],
  );

  useEffect(() => {
    return history.listen(resetSkills);
  }, [exercise, history, resetSkills]);

  return {
    addSkills,
    createAssignmentWithSkills: skills => addSkills(skills),
    createExamTemplateWithSkills: skills => addSkills(skills, EXAM_PATH),
    isSkillAdded,
    resetSkills,
  };
};

export const useSkillsEffect = (
  callback: (skills: ExerciseSkill[]) => void,
): void => {
  const { skills } = useContext(AddedSkillsContext);
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    // Needed for an e2e that somehow would have an array with a string
    const actualSkills = skills.filter(skill => isTypedItem(skill));

    if (actualSkills.length) callbackRef.current(actualSkills);
  }, [callbackRef, skills]);
};
