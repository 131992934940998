import '~/lib/config/yup';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as smoothscroll from 'smoothscroll-polyfill';
import { SWRConfig } from 'swr';

import { ThemeProvider } from '@gmm/ui';
import { Auth, AuthProvider } from '~/lib/auth';
import { Config } from '~/lib/config/config';
import ErrorBoundary from '~/lib/errorBoundary';
import { getUserConfirmation } from '~/lib/routing';

import { mutateDraft, sdkFetcher } from './lib/swr';

async function main(): Promise<void> {
  smoothscroll.polyfill();

  // Initializes locales
  await import('~/lib/config/i18n');

  // const isProduction = process.env.NODE_ENV === 'production';
  // Hot reloading. Uncomment below when HMR is fixed.
  // if (!isProduction && module.hot) {
  // module.hot.accept();
  // }

  const App = lazy(() => import('./app'));
  const AppProvider = lazy(() => import('./appProvider'));
  const baseSwrConfig = {
    fetcher: sdkFetcher,
    revalidateOnFocus: false,
    use: [mutateDraft],
  };
  const swrValue = window.Cypress
    ? { ...baseSwrConfig, dedupingInterval: 0 }
    : baseSwrConfig;

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LD_CLIENT_SIDE_ID,
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
  });

  render(
    <LDProvider>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <SWRConfig value={swrValue}>
          <AuthProvider>
            <ThemeProvider>
              <Config />
              <ErrorBoundary>
                <Auth>
                  <Suspense fallback={<noscript />}>
                    <AppProvider>
                      <App />
                    </AppProvider>
                  </Suspense>
                </Auth>
              </ErrorBoundary>
            </ThemeProvider>
          </AuthProvider>
        </SWRConfig>
      </BrowserRouter>
    </LDProvider>,
    document.getElementById('app') as HTMLElement,
  );
}

main();
