import { AxiosRequestConfig, AxiosResponseTransformer } from 'axios';

import { ExamSettingsType } from '../shared';
import { transformResponseToJSON, UnknownError } from '../utils';

export type { ExamSettingsType };

// Only meant for other SDK files, NOT web
export interface ExamSettingItem {
  autoAcceptExpiresAt: string;
  show: boolean;
  static?: boolean;
  type: ExamSettingsType;
}

interface KlassExamItem {
  id: string;
  additionalPracticePoints: number;
  createdAt: string;
  name: string;
  settings: [ExamSettingItem, ExamSettingItem, ExamSettingItem];
}

type KlassExamsIndexResponse = KlassExamItem[];

export type ExamSetting = Omit<KlassExamItem, 'settings'> &
  ExamSettingItem & {
    settings: [ExamSetting, ExamSetting, ExamSetting];
  };

export type Exam = Omit<ExamSetting, 'type'> & {
  type: ExamSettingsType | undefined;
};

export type KlassExamsListResponse = Exam[];

const makeSetting = (
  exam: KlassExamItem,
  setting: ExamSettingItem,
): ExamSetting => ({
  ...exam,
  ...setting,
  settings: [] as unknown as [ExamSetting, ExamSetting, ExamSetting],
});

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  transformResponse: AxiosResponseTransformer;
  url: `/classes/${string}/exams`;
}

/**
 * GET /api/v2/classes/{id}/exams
 */
export const list = (classId: string): ListConfig => {
  return {
    method: 'GET',
    transformResponse: (
      stringData: string,
    ): KlassExamsListResponse | UnknownError => {
      const data = transformResponseToJSON<KlassExamsIndexResponse>(stringData);

      if (!Array.isArray(data)) return data;

      return data.map<Exam>(exam => ({
        ...exam,
        autoAcceptExpiresAt: '',
        settings: [
          makeSetting(exam, exam.settings[0]),
          makeSetting(exam, exam.settings[1]),
          makeSetting(exam, exam.settings[2]),
        ],
        show: false,
        static: false,
        type: undefined,
      }));
    },
    url: `/classes/${classId}/exams`,
  } as const;
};
