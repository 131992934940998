// Warning: Editing these values may cause issues with Google Analytics and
// may also break any e2e tests as well

import { banner } from './banner';
import { classes } from './classes';
import { dataTable } from './dataTable';
import { emptyState } from './emptyState';
import { reports } from './reports';
import { work } from './work';

export const ids = {
  auth: {
    loginFail: {
      headline: 'auth-login-fail-headline',
      image: 'auth-login-fail-image',
      link: 'auth-login-fail-link',
      root: 'auth-login-fail-root',
      subheading: 'auth-login-fail-subheading',
    },
  },
  banner,
  changePassword: {
    cancel: 'change-password-form-cancel',
    confirm: 'change-password-form-confirm',
    currentPassword: 'change-password-current-password',
    errors: 'change-password-errors',
    form: 'change-password-form',
    modal: 'change-password-modal',
    newPassword: 'change-password-new-password',
    newPasswordRepeat: 'change-password-new-password-repeat',
  },
  classes,
  dataTable,
  emptyState,
  mobileSplashScreen: {
    button: (num: number): string => `mobile-splash-screen-button${num}`,
    close: 'mobile-splash-screen-close',
    content: 'mobile-splash-screen-content',
    root: 'mobile-splash-screen-root',
  },
  modals: {
    createClass: {
      cancel: 'create-class-modal-cancel',
      confirm: 'create-class-modal-save-button',
      field: 'create-class-modal-field',
      form: 'create-class-modal-form',
    },
    confirmation: {
      confirm: 'confirmation-confirm',
      deny: 'confirmation-deny',
      dialog: 'confirmation-dialog',
      title: 'confirmation-title',
    },
    removeFromClass: {
      cancel: 'delete-class-modal-cancel',
      confirm: 'delete-class-modal-confirm',
      root: 'delete-class-modal-root',
      title: 'delete-class-modal-title',
    },
    password: {
      alternate: 'student-password-modal-alternate',
      cancel: 'student-password-modal-cancel',
      errors: 'student-password-modal-errors',
      resetPassword: 'student-password-modal-reset',
      title: 'student-password-modal-title',
    },
    resetStudentPassword: {
      cancel: 'student-reset-password-cancel',
      confirm: 'student-reset-password-confirm',
      confirmButton: 'student-reset-password-confirm-button',
      errors: 'student-reset-password-errors',
      input: 'student-reset-password-input',
      root: 'student-reset-password-root',
      shuffle: 'student-reset-password-shuffle',
    },
  },
  navbar: {
    account: {
      academyLink: 'academy-link',
      changePassword: 'change-password',
      logout: 'logout-button',
      resourcesLink: 'resources-link',
      tab: 'account-tab',
      trainerSettingsLink: 'trainer-settings-link',
      helpLink: 'help-link',
    },
    classesTab: 'classes-tab',
    logo: 'gmm-logo',
    reportsTab: 'reports-tab',
    studentsTab: 'students-tab',
    workTab: 'work-tab',
  },
  onboardingChecklist: {
    back: 'onboarding-checklist-back',
    badge: (indicator: boolean): string =>
      `onboarding-checklist-indicator-${indicator}`,
    button: 'onboarding-checklist-button',
    drawer: 'onboarding-checklist-drawer',
    taskList: {
      task: (key: string, completed: boolean): string =>
        `onboarding-task-${key}-${completed}`,
    },
  },
  problemPreview: {
    add: 'skill-preview-add',
    addDisabled: 'skill-preview-add-disabled',
    area: 'skill-preview-area',
    chip: 'condensed-skill-chip',
    close: 'skill-preview-close',
    main: 'skill-preview',
    refresh: 'skill-preview-refresh',
    tabs: {
      main: 'skill-preview-tabs',
      problem: 'skill-preview-tabs-problem',
      answer: 'skill-preview-tabs-answer',
      students: 'skill-preview-tabs-students',
    },
    answerCanvas: {
      actual: 'problem-preview-answer-canvas-actual',
      wrapper: 'problem-preview-answer-canvas-wrapper',
    },
    problemCanvas: {
      actual: 'problem-preview-skill-canvas-actual',
      wrapper: 'problem-preview-skill-canvas-wrapper',
    },
    students: {
      root: 'problem-preview-students-data',
      table: 'problem-preview-students-table',
    },
  },
  reports,
  skillBankNavigator: {
    accordion: {
      icon: 'skills-menu',
    },
    collapseAll: 'skill-bank-navigator-collapse-all',
    header: 'skill-bank-navigator-header',
    list: 'skill-bank-navigator-list',
    search: 'skill-bank-search',
  },
  snackbar: {
    actionButton: 'snackbar-action-button',
    closeButton: 'snackbar-close-button',
  },
  trainerSettings: {
    close: 'trainer-settings-close',
    form: {
      stateSelector: 'trainer-settings-form-state-selector',
      stateSelectorInput: 'trainer-settings-form-state-selector-root-input',
    },
    state: (state: string): string =>
      `trainer-settings-form-state-selector-value-${state}`,
  },
  welcome: { root: 'welcome-experience' },
  work,
};

export const RAILS_API = '/api/v2';

export const EPOCH = '1970-01-01T00:00:00Z';
export const FAR_FUTURE = '9999-12-31T11:59:59Z';
export const EMAIL_PATTERN = /^[\w+\-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/i;
export const STRING_FIELD_MAX_LENGTH = 255;
export const STUDENT_NAME_MAX_LENGTH = 100;

const constants = {
  ids,
  RAILS_API,
  EPOCH,
  STRING_FIELD_MAX_LENGTH,
  STUDENT_NAME_MAX_LENGTH,
};

export const idProps = (id: string): { 'data-testid': string; id: string } => ({
  'data-testid': id,
  id,
});

export default constants;
