import { AxiosRequestConfig } from 'axios';

import {
  ExerciseDetail,
  ProblemtypeExerciseDetailItem,
  WorkItemType,
} from '../../../sdk/src/shared';
import { BASE_API } from '../utils';

export type ExercisesShowResponse = ExerciseDetail;

export type ExerciseType = Exclude<WorkItemType, 'Folder'>;

export type ExerciseSkill = ProblemtypeExerciseDetailItem;

interface BaseExercise {
  id: string;
  name: string;
  skills: ExerciseSkill[];
  type: ExerciseType;
}

export interface Assignment extends BaseExercise {
  classes: number;
  pointsToFinish: number;
  spiralReviewPoints: number | null;
  type: 'Assignment';
}

export interface ExamTemplate extends BaseExercise {
  static: boolean;
  type: 'ExamTemplate';
}

export type Exercise = Assignment | ExamTemplate;

interface PdfParams {
  format?: 'pdf';
  problemsPerSkill?: number;
  shuffle?: boolean;
  versions?: number;
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  params: Omit<PdfParams, 'format'>;
  url: `/exercises/${string}.pdf` | `/exercises/${string}`;
}

/**
 * GET /v1/exercises/{id[.pdf]}
 */
export const show = (
  id: string,
  { format, ...params }: PdfParams = {},
): ShowConfig => {
  const url =
    format === 'pdf'
      ? (`/exercises/${id}.pdf` as const)
      : (`/exercises/${id}` as const);

  return {
    baseURL: BASE_API,
    method: 'GET',
    params,
    url,
  };
};
