import {
  accordionSummaryClasses,
  alpha,
  Components,
  filledInputClasses,
  formControlLabelClasses,
  outlinedInputClasses,
  Theme,
  tooltipClasses,
} from '@mui/material';

export const makeComponents = (theme: Theme): Components => ({
  MuiAccordionSummary: {
    styleOverrides: {
      [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        transform: 'rotate(-90deg)',
        [`&.${accordionSummaryClasses.expanded}`]: {
          transform: 'rotate(0deg)',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
      },
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'primary',
      size: 'small',
    },
  },
  MuiCheckbox: {
    defaultProps: { color: 'secondary', size: 'small' },
  },
  MuiChip: {
    defaultProps: { size: 'small' },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: theme.typography.pxToRem(14),
      },
      '#axesModal': {
        borderRadius: 6,
        display: 'none',
        fontFamily: 'Arial, sans-serif',
        fontSize: 16,
        margin: 0,
        maxWidth: 340,
        minWidth: 340,
        padding: 5,
        position: 'absolute',
        zIndex: 1400,
      },

      '#axesModal .modal-content': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
        borderRadius: 6,
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
        outline: 0,
        position: 'relative',
      },

      '#axesModal .modal-header': {
        borderBottom: '1px solid #e5e5e5',
        padding: 15,
      },

      '#axesModal .modal-body': {
        padding: 15,
        position: 'relative',
      },

      '#axesModal .modal-footer': {
        borderTop: '1px solid #e5e5e5',
        padding: 15,
        textAlign: 'right',
      },

      '#axesModal h5': {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.1,
        marginBottom: 10,
        marginTop: 10,
      },

      '#axesModal .selectpicker': {
        display: 'block',
        margin: '0 auto',
        width: 120,
      },

      '#axesModal .row': {
        display: 'flex',
      },

      '#axesModal .col-xs-3': {
        flex: 1,
      },

      '#axesModal .close': {
        background: 'transparent',
        border: 0,
        color: theme.palette.common.black,
        cursor: 'pointer',
        float: 'right',
        fontSize: 21,
        fontWeight: 700,
        lineHeight: 1,
        marginTop: -2,
        opacity: 0.2,
        padding: 0,
        textShadow: '0 1px 0 #fff',
      },

      '#axesModal .btn': {
        backgroundColor: theme.palette.common.white,
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: 4,
        color: '#333',
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.42857143,
        marginBottom: 0,
        padding: '6px 12px',
        textAlign: 'center',
        touchAction: 'manipulation',
        userSelect: 'none',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
      },

      '#gmmModalScreenCover': {
        zIndex: 1400,
      },

      // These two ensure the ProblemJS popup is visible. The original `z-index`'s were
      // less than what Material UI sets, causing them to be hidden.

      '#alertProblemjsScreenCover': {
        zIndex: 1999,
      },

      '#alertProblemjs': {
        zIndex: 2000,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      [`&.${filledInputClasses.focused}`]: {
        backgroundColor: alpha(theme.palette.common.black, 0.09),
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      [`&.${formControlLabelClasses.root}`]: {
        width: 'fit-content',
      },
    },
  },
  MuiFab: {
    defaultProps: { size: 'small' },
  },
  MuiIconButton: {
    defaultProps: { size: 'small' },
  },
  MuiLink: {
    defaultProps: {
      color: 'primary',
      underline: 'hover',
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      [`&.${outlinedInputClasses.root}.${outlinedInputClasses.focused}.${outlinedInputClasses.notchedOutline}::after`]:
        {
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
        },
    },
  },
  MuiSwitch: {
    defaultProps: { color: 'secondary' },
  },
  MuiTable: {
    defaultProps: { size: 'small' },
  },
  MuiTabs: {
    defaultProps: {
      indicatorColor: 'secondary',
      textColor: 'inherit',
    },
  },
  MuiTextField: {
    defaultProps: { size: 'small' },
  },
  MuiTooltip: {
    defaultProps: {
      enterDelay: window.Cypress ? /* istanbul ignore next */ 10 : 250,
      enterNextDelay: window.Cypress ? /* istanbul ignore next */ 10 : 100,
    },
    styleOverrides: {
      tooltip: {
        [`&.${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.background.tooltip,
          color: theme.palette.common.white,
          fontSize: '1rem',
          overflowWrap: 'break-word',
        },
      },
    },
  },
});
